<template>
	<div class="bottom_box color-fff flex align-end justify-center" :style="[{background:bgWhite?'#ffffff':''}]">
		<div class="flex align-end justify-center">
			<div v-for="item in navList" :key="item.id" @click='goPage(item)' class="text-center">
				<div class="flex align-center text-999 fs12  point padding-right-10">
					<span  class="padding-right-7">{{item.title}}</span>
					<span v-if="item.id<7">|</span>
				</div>
			</div>
			<div class="text-center">
				<el-tooltip placement="top" >
				  <div slot="content">
					  <div class="padding-lr-5 padding-tb-5 flex flex-direction align-center justify-center">
					  	<img src="../assets/image/download.png" class="block-150">
						<p class="margin-top-sm">扫码下载App</p>
					  </div>
				  </div>
				  <span  class=" text-999 point fs12 padding-right-10">下载App</span>
				  <!-- <el-button type="text" class="text-black">下载App</el-button> -->
				</el-tooltip>		
			</div>
		</div>
		<!-- <p class=" text-center text-999 fs12 padding-lr-20">京公网安备11000002000001号</p> -->
		<p class=" text-center text-999 fs12 point" @click="toHref">
				京ICP备18057210号-2
		</p>
	</div>
</template>

<script>
	export default {
		name: 'bottom',
		props: {
			bgWhite: [String, Boolean]
		},
		data() {
			return {
				active:0,//选择的索引
				navList: [{
						id: 1,
						title: '首页',
						img: require('../assets/image/icon/icon1.png'),
						path: '/'
					},
					{
						id: 25,
						title: '关于我们',
						img: require('../assets/image/icon/icon2.png'),
						path: '/about'
					},
					{
						id: 26,
						title: '人才库手册',
						img: require('../assets/image/icon/icon3.png'),
						path: '/about'
					},
					{
						id: 27,
						title: '社区规范',
						img: require('../assets/image/icon/icon4.png'),
						path: '/about'
					},
					{
						id: 15,
						title: '服务协议',
						img: require('../assets/image/icon/icon5.png'),
						path: '/about'
					},
					{
						id: 23,
						title: '隐私协议',
						img: require('../assets/image/icon/icon5.png'),
						path: '/about'
					},
					// {
					// 	id: 25,
					// 	title: '下载App',
					// 	img: require('../assets/image/icon/icon5.png'),
					// 	path: '/about'
					// },
				],
			}
		},
		mounted() {
			// this.getList()
		},
		methods: {
			toHref(){
				window.open("https://beian.miit.gov.cn/#/Integrated/recordQuery");      //在另外新建窗口中打开窗口
			},
			goPage(item) {
				this.toBlank({
					path:item.path,
					query:{
						type:item.id
					}
				})
			},
			getList() {
				this.ajax('post', '/index/about', {}, res => {
					console.log(res)
					if (res.code == 1) {
						this.detail = res.data
					}
				}, err => {
					console.log('err', err)
				})
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.bottom_box {
		height: 44px;
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		z-index: 10000;
		background-color: #F8F8F8;
		line-height: 44px;
	}

	.code {
		width: 80px;
		height: 80px;
		margin-right: 20px;
	}

	.margin-top {
		margin-top: 10px;
	}
</style>
