<template>
  <div class="padding-top-10 contain_pay">
    <top :bgWhite="true"></top>
    <div class="content">
        <div class="item" v-for="(item,index) in lists" :key="index" @click="todetail(item)">
            <div>
                <div style="color:#707070">快递单号</div>
                <div style="margin-top:24px;color:#707070">快递公司</div>
                <div style="margin-top:24px;color:#707070">邮寄材料内容描述</div>
            </div>
            <div style="text-align:right">
                <div>{{ item.express_no }}</div>
                <div style="margin-top:24px;">{{ item.express_name }}</div>
                <div style="margin-top:24px;">{{ item.express_suggest }}</div>
            </div>
        </div>
    </div>
    <bottom></bottom>
  </div>
</template>

<script>
import payCode from "@/components/pay-code/pay-code.vue";
import top from "@/components/top";
import bottom from "@/components/bottom";
export default {
  name: "authen",
  components: {
    bottom,
    top,
    payCode,
  },
  data() {
    return {
      url: "", //
      timer: null,
      activeName: "0",
      page:1 , 
      totalSize:0,//总页数
      type:'',
      lists:[],
      isInit:false,
      currentIndex: 0, //选择的索引
      order_sn: "",
    };
  },
  mounted() {
      this.loadData()
  },
  methods: {
      handleClick(tab, event) {
        console.log(tab, event);
      },
    loadData(){
         this.ajax('post', '/v1/6387339e799cb', {
                      keyword:1
				  },res => {
                      this.isInit = true;
                     this.totalSize = res.data.total;
                        this.lists =  res.data.data    
                        console.log( res.data.data)             
                  })
    },
    //去详情
    todetail(item){
        this.$router.push({
							path: '/express-detail',
							query:{
								aid:item.aid,
							}
						})
    },
    	// 页数改变
			handleSizeChange(e){
				console.log(e);
				this.page = e;
				this.loadData();
				target_top.scrollIntoView();
            },
            // 跳页
			handleCurrentChange(e){
				console.log(e);
				this.page = e;
				this.loadData();
				target_top.scrollIntoView();
			},
  },
};
</script>

<style scoped>
.contain_pay {
  width: 100vw;
  height: 100vh;
  background-color: #F6F7F9;
  overflow: auto;
}
.content {
  width: 69%;
  height: auto;
  margin: 80px  auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.item{
    background: #fff;
    padding: 24px;
    margin-bottom: 48px;
    border-radius: 16px;
    width: 44%;
    display: flex;
    justify-content: space-between;
    font-size: 14px;

}
</style>
