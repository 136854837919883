<template>
	<div class="">
		<div class="flex align-center">
			<div class='am_payPwd' :id="`ids_${id}`">
			  <input type="password"
			    maxlength="1"
				  autofocus
			    @input="changeInput"
			    @click="changePwd"
			    v-model="pwdList[i]"
			    @keyup="keyUp($event)"
			    @keydown="oldPwdList = pwdList.length"
			    class="shortInput"
			    v-for="(v, i) in 6" :key="i">
			</div>
			<div class="" @click="toSetPwd">
				<span class="margin-left point text-blue">忘记密码？</span>
			</div>
		</div>
		<div class="margin-top flex justify-end">
			<el-button  :disabled="disabled" type="primary" @click="pswConfirm">确认</el-button>
		</div>
	</div>
</template>
<script>
	export default {
		data () {
		    return {
			  disabled:true,
		      pwdList: [],
		      oldPwdList: [],
		      isDelete: false,
		      ipt: ''
		    }
		  },
		  props: {
		    id: String, // 当一个页面有多个密码输入框时，用id来区分
		    default: '1'
		  },
		  mounted () {　　
		    this.ipt = document.querySelectorAll(`#ids_${this.id} .shortInput`)
		  },
		  methods: {
			// 清除密码
			clearPsw(){
				// this.pwdList = '';
				// this.disabled = true
			},
			// 跳转设置密码页面
			toSetPwd(){
				this.$rouer.push({
					path:'/setting',
					query:{
						type:1
					}
				})
			},
		    keyUp (ev) {
		      let index = this.pwdList.length
		      if (!index) return
		      if (ev.keyCode === 8) {
		        this.isDelete = true
		        if (this.oldPwdList === this.pwdList.length) {
		          if (index === this.pwdList.length) {
		            this.pwdList.pop()
		          }
		          index--
		        } else {
		          index > 0 && index--
		        }
		        this.ipt[index].focus()
		      } else if (this.isDelete && index === this.pwdList.length && /^\d$/.test(ev.key)) {
		        this.isDelete = false
		        this.pwdList.pop()
		        this.pwdList.push(ev.key)
		        this.ipt[this.pwdList.length] && this.ipt[this.pwdList.length].focus()
		      }
			  if(index<6){
				  this.disabled = true;
			  }else{
				  this.disabled = false;
			  }
		      this.$emit('getPwd', this.pwdList.join(''))
		    },
		    changePwd () {
		      let index = this.pwdList.length
		      index === 6 && index--
		      this.ipt[index].focus();
		    },
		    changeInput () {
		      let index = this.pwdList.length
		      let val = this.pwdList[index - 1]
		　　   if (!/[0-9]/.test(val)) {　
		        this.pwdList.pop() 　　　　  　　　　 
		        return 　　　 　　
		      }
		      if (!val) {
		        this.pwdList.pop()
		        index--
		        if (index > 0) this.ipt[index - 1].focus()
		      } else {
		        if (index < 6) this.ipt[index].focus()
		      }
		    },
			// 确认密码
			pswConfirm(){
				if(this.pwdList.length<6) return;
				let pwd = this.pwdList.join('')
				this.$emit('inputPsw',pwd)
			}
		  }
	}
</script>
<style scoped lang="scss">
	.text-blue{
		color: #098EF0;
	}
	.am_payPwd {
	  display: inline-block;
	  width: 280px;
	  height: 26px;
	  border: 1px solid #999;
	  border-radius: 5px;
	  padding: 10px 0;
	  position: relative;
	  margin-left: 1px;
	  .shortInput {
	    text-align: center;
	    font-size: 20px;
	    float: left;
	    width: 40px;
	    height: 20px;
	    color: #333;
	    outline: #ff0067;
	    &:not(:last-child) {
	      border-right: 1px solid #999;
	    }
	  }
	}
</style>
